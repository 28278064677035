import { put, call, takeLatest } from 'redux-saga/effects';
import * as API from 'services';
import * as Actions from 'actions/Reports';
import { ReportsInfo } from 'constants/Actions';

const { getReportList } = API.reports;

const { reportsInfo } = Actions;

const { reportList } = reportsInfo;

const ReportInfoSagas = {
	reportInfo: {
		getReportsList: function* (action: any) {
			try {
				const { data, status } = yield call(
					getReportList,
					{}
				);
				if (data) {
					yield put(reportList.success(data));
				} else {
					yield put(reportList.fail([]));
				}
			} catch (error) {
				console.error(error);
				yield put(reportList.fail([]));
			}
		},
	},
};

export default [
	takeLatest(
		ReportsInfo.GET_REPORT_INFO,
		ReportInfoSagas.reportInfo.getReportsList
	),
];
