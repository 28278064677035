import React from 'react';
import { useTranslation } from 'react-i18next';
import { $Popconfirm } from 'us.common/components';
import { BoxIcons, IconTypes, EditOutlined, DeleteOutlined } from 'us.icons';
import { IItemMenu } from 'components/Interfaces';
import { ReportsAction } from 'components/Constants';

const ItemMenu: React.FC<IItemMenu> = ({ onCallAction }) => {
	const { t } = useTranslation();

	return (
		<div
			className='table-more-content'
			data-testid='popover-content'>
			<div
				className='d-flex  flex-row more-item'
				data-testid='view-btn'
				onClick={() =>
					onCallAction(ReportsAction.VIEW_REPORT)
				}>
				<div className='p-1'>
					<BoxIcons
						type={IconTypes.BOX_ICON}
						name='preview'
					/>
				</div>
				<div className='p-1'>
					{t(
						'US.COLLECTION.REPORTS:REPORTS.VIEW_REPORT'
					)}
				</div>
			</div>
			{/* <div className="d-flex  flex-row more-item">
        <div className="p-1">
          <BoxIcons type={IconTypes.BOX_ICON} name="edit_query" />
        </div>
        <div className="p-1" onClick={() => onCallAction(ReportsAction.EDIT_QUERY)}>
          {t("US.COLLECTION.REPORTS:REPORTS.EDIT_QUERY")}
        </div>
      </div>
      <div className="d-flex  flex-row more-item">
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1" onClick={() => onCallAction(ReportsAction.EDIT)}>
          {t("COMMON.EDIT")}
        </div>
      </div>
      <$Popconfirm
        title={t(
          "US.COLLECTION.ADMIN:COURT.DO_YOU_WANT_TO_DELETE_THE_COURT_ITEM?"
        )}
        onConfirm={() => {
          onCallAction(ReportsAction.DELETE);
        }}
      >
        <div
          className="d-flex mb-2 flex-row more-item text-error"
          data-testid="delete-btn"
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm> */}
		</div>
	);
};

export default ItemMenu;
