import React from "react";
import { Route } from "react-router-dom";
import { Cnt_Reports, Cnt_Reports_Page, Cnt_MainSearch } from "containers";

export default (caseNo: number) => {
  return [
    <Route
      exact
      key="reports"
      path={"/"}
      render={(props) => <Cnt_Reports {...props} />}
    />,
    <Route
      exact
      key="reporting-page"
      path={"/reporting-page"}
      render={(props) => <Cnt_Reports_Page {...props} />}
    />,
    <Route
      exact
      key="search"
      path={"/search"}
      render={(props) => <Cnt_MainSearch {...props} />}
    />,
  ];
};
