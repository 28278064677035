import { initialState } from "reducers/Reports/State";
import { ReportsInfo } from "constants/Actions";

const { GET_REPORT_INFO, GET_REPORT_INFO_SUCCESS, GET_REPORT_INFO_FAIL } =
  ReportsInfo;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_REPORT_INFO:
      return Object.assign({}, state, {
        reportsList: [],
        reportsLoading: true,
      });
    case GET_REPORT_INFO_SUCCESS:
      let labels: any = [];
      payload?.data.map((item: any, index: number) => {
        labels.push({ key: index, label: item.group });
      });
      return Object.assign({}, state, {
        reportsList: payload?.data,
        reportsLoading: false,
        menuLabels: (labels.filter(
          (value:any, index: any, self: any) =>
            index ===
            self.findIndex(
              (t:any) => t.label === value.label
            )
        )),
      });
    case GET_REPORT_INFO_FAIL:
      return Object.assign({}, state, {
        reportsList: [],
        reportsLoading: false,
      });
    default:
      return state;
  }
};
