import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	$Affix,
	$Menu,
	$PageHeader,
	$Divider,
	$Button,
	$Search,
	$Skeleton,
	$Empty,
} from 'us.common/components';
import './Home.scss';
import { PlusOutlined, UploadOutlined } from 'us.icons';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { ReportsTable } from 'components/Reports/Components/ReportsTable';
import {
	ITableInfo,
	IReportInfoAction,
	IReportInfo,
	IReports,
} from 'components/Interfaces';
import { DEFAULT_TABLE_INFO, ReportsAction } from 'components/Constants';
import * as Actions from 'actions/Reports';
import { connect, ConnectedProps } from 'react-redux';
import { getRouteUrl } from 'us.helper';
import { handleSearchMenu, handleMenuClick } from 'components/Functions';

/**
 * @description - Reporting Module
 * @link https://unicorn-solutions.atlassian.net/browse/UBX4-12251
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 02/06/2022
 */

const Reports: React.FC<PropsFromRedux & IReports> = (props) => {
	const { getReportsList, reportsList, menuLabels, reportsLoading } =
		props;
	const { t } = useTranslation([
		'US.COLLECTION.REPORTS',
		'US.COLLECTION.COMMON',
	]);
	const [tableInfo, setTableInfo] =
		useState<ITableInfo>(DEFAULT_TABLE_INFO);

	useEffect(() => {
		getReportsList && getReportsList({});
	}, []);

	useEffect(() => {
		setTableInfo({
			...tableInfo,
			data: reportsList.filter(
				(item: any) =>
					item.group ===
					menuLabels[
						tableInfo?.selectedMenuKeys[0]
					].label
			),
			filtersMenuItems: menuLabels,
		});
	}, [reportsList]);

	/**
	 * @description handle report item menu actions
	 * @param action - action of clicked menu icon
	 * @param record - selected court item for action
	 */
	const onHandleReportsAction = (
		action: IReportInfoAction,
		record: IReportInfo
	) => {
		const { url } = record;
		switch (action) {
			case ReportsAction.VIEW_REPORT:
				getRouteUrl.reportPage(url);
				break;
			default:
				break;
		}
	};

	const handleSearchMenuItems = (searchText: string) => {
		const searchedMenu = handleSearchMenu(menuLabels, searchText);
		if (searchedMenu.length > 0) {
			setTableInfo({
				...tableInfo,
				filtersMenuItems: searchedMenu,
				data: reportsList.filter(
					(report: any) =>
						report?.group ===
						searchedMenu[0]?.label
				),
				selectedMenuKeys: searchedMenu[0]?.key,
			});
		}
	};

	return (
		<Formik
			initialValues={{}}
			enableReinitialize
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<div className='space-content'>
						<$Affix offsetTop={48}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.REPORTS:REPORTS.REPORTS'
											)}
											onBack={() => 
												(window.location.href = window._ENV.REACT_APP_HOME_URL)
											}
										/>
										{/* <$Divider className="bui-devider" type="vertical" />
                    <$Button
                      type="default"
                      size="small"
                      className="mr-2"
                      icon={<PlusOutlined />}
                      onClick={() => {}}
                    >
                      {t("US.COLLECTION.REPORTS:REPORTS.NEW_REPORTS")}
                    </$Button>
                    <$Button
                      type="default"
                      size="small"
                      icon={<UploadOutlined />}
                    >
                      {t("US.COLLECTION.REPORTS:REPORTS.UPLOAD_REPORTS")}
                    </$Button> */}
									</div>
								</div>
							</div>
						</$Affix>
					</div>
					<div className='report-layout'>
						<$Affix offsetTop={96}>
							<aside className='report-layout-side'>
								{/* <div className="reportlist-search">
                  <$Search
                    size="small"
                    name="reportSearch"
                    className="w-100"
                    allowClear={true}
                    placeholder={t("US.COLLECTION.COMMON:COMMON.SEARCH")}
                    onSearch={(e: any) => handleSearchMenuItems(e)}
                    onPressEnter={(e: any) => handleSearchMenuItems(e)}
                  />
                </div> */}
								<div>
									{tableInfo
										?.filtersMenuItems
										?.length >
										0 && (
										<$Menu
											defaultSelectedKeys={
												tableInfo?.selectedMenuKeys
											}
											selectedKeys={[
												tableInfo?.selectedMenuKeys.toString() ??
													'1',
											]}
											className='report-type-menu'>
											{tableInfo?.filtersMenuItems?.map(
												(
													item: any
												) => (
													<$Menu.Item
														key={
															item.key
														}
														onClick={() =>
															setTableInfo(
																{
																	...tableInfo,
																	selectedMenuKeys:
																		[
																			item.key,
																		],
																	data: reportsList.filter(
																		(
																			report: any
																		) =>
																			report.group ===
																			handleMenuClick(
																				menuLabels,
																				item.key
																			)
																	),
																}
															)
														}>
														{
															item.label
														}
													</$Menu.Item>
												)
											)}
										</$Menu>
									)}
									{tableInfo
										?.filtersMenuItems
										?.length ===
										0 && (
										<$Empty
											image={
												$Empty.PRESENTED_IMAGE_SIMPLE
											}
											description={t(
												'US.COMMON:COMMON.NO_DATA'
											)}
										/>
									)}
								</div>
							</aside>
						</$Affix>
						<$Skeleton
							className={
								reportsLoading &&
								'p-3'
							}
							loading={reportsLoading}
							active
							paragraph={{ rows: 2 }}>
							<div className='flex-fill'>
								<div className='report-layout-content'>
									<ReportsTable
										data={
											tableInfo.data
										}
										onCallAction={
											onHandleReportsAction
										}
										handleDoubleClick={(
											record: any
										) => {
											const {
												url,
											} =
												record;
											getRouteUrl.reportPage(
												url
											);
										}}
									/>
								</div>
							</div>
						</$Skeleton>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: any) => {
	const { reports } = state;
	const { reportsList, menuLabels, reportsLoading } = reports;
	return {
		reportsList,
		menuLabels,
		reportsLoading,
	};
};

const { reportsInfo } = Actions;
const { reportList } = reportsInfo;

const mapDispatchToProps = {
	getReportsList: reportList.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Reports);
