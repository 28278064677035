import { ReportsInfo } from "constants/Actions";

export interface IReturnAction<T> {
    type: string;
    payload: {
      data: Array<T> | object;
      isLoading: true;
    };
  }

  interface IReturnSuccessAction<T>
  extends Readonly<Pick<IReturnAction<T>, "type">> {
  payload: {
    data: Array<T> | object;
    isLoading: false;
  };
}

export interface IReturnFailAction<E>
  extends Readonly<Pick<IReturnAction<E>, "type">> {
  payload: {
    error: Array<E> | object;
    isLoading: false;
  };
}

export interface IAPIAction {
    [key: string]: {
      save?: <T>(data: Array<T> | object) => IReturnAction<T>;
      update?: <T>(data: Array<T> | object) => IReturnAction<T>;
      search?: <T>(data: Array<T> | object) => IReturnAction<T>;
      set?: <T>(data: Array<T> | object) => IReturnAction<T>;
      get?: <T>(data: Array<T> | object) => IReturnAction<T>;
      reset?: <T>(data: Array<T> | object) => IReturnAction<T>;
      success: <T>(data: Array<T> | object) => IReturnSuccessAction<T>;
      fail: <E>(data: Array<E> | object) => IReturnFailAction<E>;
      openDrawer?: <T>(data: Array<T> | object) => IReturnAction<T>;
    };
  }

export const reportsInfo: Readonly<IAPIAction> = {
  reportList: {
    get: (data) => ({
      type: ReportsInfo.GET_REPORT_INFO,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ReportsInfo.GET_REPORT_INFO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ReportsInfo.GET_REPORT_INFO_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  }
};
