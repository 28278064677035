import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import "./ReportingPage.scss";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { IViewReports } from "components/Interfaces";
import * as Azure from "us.helper/azure";
import { versionedHttpCollection } from "us.helper/http/collection";
import Common from "us.common";

const { $Skeleton } = Common.Components;
/**
 * @description - Reporting Module
 * @link https://unicorn-solutions.atlassian.net/browse/UBX4-12251
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 02/06/2022
 */

const ReportingPage: React.FC<PropsFromRedux & IViewReports> = (props) => {
  const auth = new Azure.ADAuth();
  const { t } = useTranslation([
    "US.COLLECTION.REPORTS",
    "US.COLLECTION.COMMON",
  ]);
  const { search } = useLocation();
  const [sessionToken, setsessionToken] = useState({
    url: "",
    isLoading: true,
  });
  const params = queryString.parse(search);
  const url = params?.urlId ? params?.urlId : undefined;
  var uri = window.location.toString();

  /**
   * @description add sessiontoken to the iframe url as a query string
   */

  const getSessionToken = async () => {
    const { data } = await versionedHttpCollection.reporting.get(
      "reportservice",
      "auth/sessiontoken",
      {},
      "v1"
    );
    const { sessionToken }: any = data;
    return sessionToken;
  };

  useEffect(() => {
    getSessionToken().then((token: string) => {
      setsessionToken({
        isLoading: false,
        url: `${url}?sessiontoken=${token}`,
      });
    });
  }, [url]);

  // if (uri.indexOf("?") > 0) {
  //   var clean_uri = uri.substring(0, uri.indexOf("?"));
  //   window.history.replaceState({}, document.title, clean_uri);
  // }
  return (
    <div>
      <$Skeleton
        loading={sessionToken.isLoading}
        active
        paragraph={{ rows: 2 }}
      >
        <iframe
          className="report-iframe"
          frameBorder="0"
          src={`${sessionToken.url}?userId=${auth.currentUser().oid}`}
        />
      </$Skeleton>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportingPage);
