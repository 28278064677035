import { all } from 'redux-saga/effects';

import commonSagas from 'us.common/sagas'
import reportSagas from 'sagas'

let sagas = [
  ...commonSagas,
  ...reportSagas
];

function* rootSaga() {
  yield all(sagas);
}

export default rootSaga;