import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Components from 'us.common/components';
import {
	COLUMNS,
	ReportsTableColumn,
	Align,
	ITEM_MENU_DEFAULT_STATE,
} from 'components/Constants';
import { MoreOutlined } from 'us.icons';
import { ItemMenu } from './Components';
import { IReportsTableInfo } from 'components/Interfaces';
import { getColumnWidth } from 'components/Functions';

const { $Button, $TableTree, $Popover, $Empty, $DateLabel } = Components;

/**
 * @description - Reporting Module
 * @link https://unicorn-solutions.atlassian.net/browse/UBX4-12251
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 02/06/2022
 */

const ReportsTable: React.FC<IReportsTableInfo> = (props) => {
	const { onCallAction, handleDoubleClick, data } = props;
	const { t } = useTranslation([
		'US.COLLECTION.REPORTS',
		'US.COLLECTION.COMMON',
	]);

	const [itemMenu, setItemMenu] = useState<{
		visible: boolean;
		id: number;
		visibleTooltip: boolean;
	}>(ITEM_MENU_DEFAULT_STATE);

	/**
	 * @description - Generate table columns with sorter and filters
	 * @returns - An array of columns
	 */
	const getColumns: any = () => {
		let columns: Array<any> = [];
		COLUMNS.map(({ id, key, title }, _index) => {
			const columnWidth = getColumnWidth(key);
			let column: any = {
				key,
				dataIndex: key,
				title:
					title &&
					t(
						`US.COLLECTION.REPORTS:REPORTS.${title}`
					),
			};
			if (key === ReportsTableColumn.MENU) {
				column = {
					...column,
					align: Align.CENTER,
					width: columnWidth,
					customRenderChild: (
						_text: any,
						record: any
					) => {
						return (
							<$Popover
								placement='rightTop'
								trigger='click'
								visible={
									itemMenu.visible &&
									itemMenu.id ===
										record.id
								}
								onVisibleChange={(
									visible: boolean
								) => {
									setItemMenu(
										{
											...itemMenu,
											visible,
											id: record.id,
										}
									);
								}}
								content={
									<ItemMenu
										onCallAction={(
											actionType: any
										) => {
											setItemMenu(
												ITEM_MENU_DEFAULT_STATE
											);
											onCallAction(
												actionType,
												record
											);
										}}
									/>
								}
								destroyTooltipOnHide>
								<$Button
									id='btnItemMenu'
									type='default'
									icon={
										<MoreOutlined />
									}
									size='small'
								/>
							</$Popover>
						);
					},
				};
			} else if (key === ReportsTableColumn.MODIFIED_DATE) {
				column = {
					...column,
					align: Align.LEFT,
					width: columnWidth,
					customFilter: true,
					showFilters: true,
					customRenderChild: (
						text: any,
						record: any
					) => {
						return (
							text != null && (
								<$DateLabel
									value={
										text
									}
								/>
							)
						);
					},
					customSorter: (a: any, b: any) =>
						a.localeCompare(b),
				};
			} else {
				column = {
					...column,
					align: Align.LEFT,
					width: columnWidth,
					customFilter: true,
					showFilters: true,
					ellipsis: true,
					customRenderChild: (
						text: any,
						record: any
					) => {
						return <span>{text}</span>;
					},
					customSorter: (a: any, b: any) =>
						a.localeCompare(b),
				};
			}
			columns.push(column);
		});
		return columns;
	};

	return (
		<$TableTree
			data-testid='reports-Info-table'
			columns={getColumns()}
			rowKey={(record) => record.id}
			data={data}
			className='mt-3'
			bordered
			size='small'
			onRow={(record, rowIndex) => {
				return {
					onDoubleClick: (event) => {
						handleDoubleClick(record);
					},
				};
			}}
			onSort={(sortData, dataSource) => {
				return sortData(dataSource);
			}}
			onFilter={(searchData, dataSource) => {
				return searchData(dataSource);
			}}
			filterOnType
			resetOnSourceChange
			showResetAllFilters
			pagination={{ defaultPageSize: 200 }}
			scroll={{ x: 800, y: 'calc(100vh - 260px)' }}
			locale={{
				emptyText: (
					<$Empty
						image={
							$Empty.PRESENTED_IMAGE_SIMPLE
						}
						description={
							<div className='text-center'>
								<div>
									{t(
										'US.COLLECTION.COMMON:COMMON.NO_DATA'
									)}
								</div>
							</div>
						}
					/>
				),
			}}
		/>
	);
};

export default ReportsTable;
