import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'us.style/antd.less';
import 'us.style/index.scss';
import { Loader } from 'us.common/components/Loader';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import store, { history } from 'store';
import * as Azure from 'us.helper/azure';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { setupTitle } from 'us.helper';

window.APP_MODULE = 'reports';

export const msalInstance = new PublicClientApplication(Azure.msalConfig);

// Default to using the first account if no account is active on page load
if (
	!msalInstance.getActiveAccount() &&
	msalInstance.getAllAccounts().length > 0
) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: any) => {
	if (
		event.eventType === EventType.LOGIN_SUCCESS &&
		event.payload.account
	) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

// set the dynamic title
setupTitle();

const app = (
	<Provider store={store}>
		<Suspense fallback={<Loader />}>
			<App history={history} msalInstance={msalInstance} />
		</Suspense>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
