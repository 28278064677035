import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	reports: (params: any): Promise<any> => {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const result =
					await versionedHttpCollection.reporting.get(
						'reporting',
						'box4/default/finance/test_report',
						params,
						ServiceConfig()[`reporting`][
							'reports'
						]
					);
				resolve(result);
			} catch (error) {
				reject(error);
			}
		});
	},
	getReportList: (params: any): Promise<any> => {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const result =
					await versionedHttpCollection.get(
						'reportservice',
						`reports/usbox4/${window._ENV.REACT_APP_CLIENT}`,
						{},
						'v1'
					);
				if (result) {
					resolve(result);
				} else {
					reject({});
				}
			} catch (error) {
				console.error(error);
				reject(error);
			}
		});
	},
};
