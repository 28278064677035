import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "us.common";
import { History } from "history";
import { reports } from "reducers/Reports";

const reducers = {
  ...Common.Reducers,
  reports
};

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

export default rootReducer;
