export enum ReportsTableColumn {
  MENU = "menu",
  NAME = "name",
  DESCRIPTION = "description",
  MODIFIED_DATE = 'modifiedDate'
}

export enum ReportsAction {
  VIEW_REPORT = "VIEW_REPORT",
  EDIT_QUERY = "EDIT_QUERY",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export enum ColumnSearchType {
  AMOUNT = "amount",
  DATE = "date",
  ACCOUNT_NO = "accountNo",
  STRING = "string",
  NUMBER = "number",
}

export const COLUMNS = [
  {
    id: 0,
    key: ReportsTableColumn.MENU,
    title: "",
  },
  {
    id: 1,
    key: ReportsTableColumn.NAME,
    title: "REPORT_NAME",
  },
  {
    id: 2,
    key: ReportsTableColumn.DESCRIPTION,
    title: "DESCRIPTION",
  },
  {
    id: 3,
    key: ReportsTableColumn.MODIFIED_DATE,
    title: "MODIFIED_DATE",
  },
];

export const DEFAULT_COLUMN_FILTERS = {
  name: "",
  description: "",
};

export const DEFAULT_TABLE_INFO = {
  filters: {},
  sorter: { columnKey: "", order: "" },
  data: [],
  selectedMenuKeys: ["0"],
  filtersMenuItems: [],
};

export enum Align {
  CENTER = "center",
  RIGHT = "right",
  LEFT = "left",
}

export const ITEM_MENU_DEFAULT_STATE = {
  visible: false,
  id: -1,
  visibleTooltip: false,
};