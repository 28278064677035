import { IColumnSearchType } from "components/Interfaces";
import { ColumnSearchType, ReportsTableColumn } from "components/Constants";

/**
 * @description - Get dynamic width property value for each column by id
 * @param key - Column key
 * @returns - Width (px)
 */
export const getColumnWidth = (key: string): string => {
  try {
    if (key === ReportsTableColumn.MENU) {
      return '45px';
    } else if (key === ReportsTableColumn.NAME) {
      return '30%';
    } else if (key === ReportsTableColumn.DESCRIPTION) {
      return '50%';
    } else if (key === ReportsTableColumn.MODIFIED_DATE) {
      return '20%';
    } else {
      return '100px';
    }
  } catch (error) {
    return '100px';
  }
};

/**
 * @description - Get type of column (`IColumnSearchType`) by column id
 * @param title - Column title
 * @returns column type - `IColumnSearchType`
 */
export const getColumnType = (title: string): IColumnSearchType => {
  try {
    return ColumnSearchType.STRING;
  } catch (error) {
    return ColumnSearchType.STRING;
  }
};

/**
 * @description Check whether any column filters include or not
 * @param current filters Array
 * @returns boolean `true` if has any filters
 */
export const hasFilters = (filters: Array<any>): boolean => {
  const filterItems = [];
  try {
    filters.map(({ value }: { dataIndex: string; value: any }) => {
      if (value) {
        filterItems.push(value);
      }
    });
  } catch (error) {
    console.error(error)
  }
  return filterItems.length > 0;
};

/**
 * @description search menu list by search text
 * @param menuItems Menu item array
 * @param searchedText searched text
 * @returns menu list searched by search text
 */
export const handleSearchMenu = (
  menuItems: Array<any>,
  searchedText: string
): any => {
  let searchedData = menuItems;

  try {
    searchedData = menuItems.filter((item: any) => {
      return item.label
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchedText?.toString()?.toLowerCase());
    });
  } catch (error) {
    console.error(error);
  }
  return searchedData;
};

/**
 * @description search menu list by search text
 * @param menuItems Menu item array
 * @param searchedText searched text
 * @returns menu list searched by search text
 */
 export const handleMenuClick = (
  menuLabels: Array<any>,
  itemKey: string
): string => {
  try {
    return (menuLabels.filter((menu:any) => menu?.key === itemKey)[0]?.label)
  } catch (error) {
    console.error(error);
    return ''
  }
};